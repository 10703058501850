import { hasData } from '@/lib/utils/hasData';
import { memo, useContext, useEffect,  useState } from 'react';
import ReactPlayer from 'react-player/file'
import Slider from 'react-input-slider';
import { useSWRWrapper } from '@/lib/utils/hooks/swr-hooks';
import { config } from '@/lib/config';
import { PlayerContext } from './player-wrapper';
import { flairObject } from '@/lib/utils/flairObject';
import { PushContext } from '../context/push-wrapper';

export function AudioLiveNested({
     stream_url
    ,content_id
    ,content_table
    ,bol_stream_local
    ,host
    ,playerRef
    ,contributor_level
    ,bol_flair
    ,flair_json
    ,userdata
}) {


    const [isListening,set_isListening] = useState(false)
    const [isMuted,set_isMuted] = useState(false)
    const [volume, set_volume] = useState(80)
    const [stream_url_active,set_stream_url_active] = useState(stream_url)

    const {player_state,set_player_state} = useContext(PlayerContext)

    const ably = useContext(PushContext);

    const channel_id = `${process.env.NEXT_PUBLIC_BRAND_ID}-${config.websockets.listen}${player_state.user_id}`

    useEffect(() => {
        return () => {
        onUnmount(ably?.channels?.get(channel_id));
        }
    }, []);



  
      useEffect(()=> {

       // console.log("useEffect in audio-file line 33")

        if (typeof window !== 'undefined' && !player_state?.isBrowserDetected) {

            let isSafari = /^((?!chrome|android).)*safari/i.test(window?.navigator?.userAgent)

            if (player_state?.bol_stream_local?.toString() == "1" 
            && !isSafari 
            && player_state?.player_type == "live") {
                
                set_player_state({...player_state
                    ,useHLS:true
                })
            }

            //make sure this useEffect doesn't repeat itself
            set_player_state({...player_state
                ,isBrowserDetected:true
            })
        }
        return () => {};
      },[ 
           player_state
           ,set_player_state
        ])




   
    useEffect(()=>{

        if (player_state?.broadcast_status == "1") {
            if (stream_url_active !== player_state?.file) set_stream_url_active(player_state?.file)
        }
        
    },[
         player_state?.broadcast_status
        ,player_state?.file
        ,stream_url_active
    ])

    
   //controls pause / play
    useEffect(()=>{

        if (isListening) {
            listenEnd(content_id,content_table,ably?.channels?.get(channel_id));
        } else {
           listenStart(content_id,content_table,ably?.channels?.get(channel_id));
        }
    },[
        player_state?.isPlaying
    ])

    //controls muted controls
    useEffect(()=>{
       if (player_state?.isMuted) {
            doMute();
       } else {
            doUnmute();
       }
    },[
        player_state?.isMuted
    ])

  //LISTENER TRACKING

  //type 1 = start
  //type 2 = ping
  //type 0 = end
  useSWRWrapper(
            player_state?.listener //id
            ,`/api/public/v0/broadcast/listen?id=${content_id}&table=${content_table}&host=${host}&type=2&origin=local&listener=${player_state?.listener}` //key
            ,true //retry?
            ,config.max_retries //max number of retries
            ,config.retry_interval //retry interval
            ,config.broadcast.listener_ping //refreshinterval (0=none)
            )


            const onMount = async(channel,userdata) => {
        
                var custom_flair = ''
        
                channel.presence.enter({
                    id: userdata?.id
                    ,user_name: userdata?.user_name
                    ,avatar_url: userdata?.avatar_url
                    ,avatar_url_sfw: userdata?.avatar_url_sfw
                    ,avatar_crdate: userdata?.avatar_crdate
                    ,bol_nsfw: userdata?.bol_nsfw
                    ,contributor_level: userdata?.contributor_level
                    // ,verified: userdata?.verified
                    ,custom_flair: custom_flair
                    ,listen: true
                    });
        
            }
        
                const onUnmount = (channel) => {
        
                    if (channel) channel.presence.leave();
                        // channel.presence.unsubscribe();
                        // channel.unsubscribe();
        
                }
            
            const doMute = () => {
                listenEnd(content_id,content_table,ably?.channels?.get(channel_id))
                set_isMuted(true);
            }
            
            const doUnmute = () => {
                listenStart(content_id,content_table,ably?.channels?.get(channel_id))
                set_isMuted(false);
            }


    //track start playing at stream start
    const listenStart = async (content_id,content_table,channel) => {

        set_isListening(true);
        set_isMuted(false);

        await onMount(channel,userdata);

        //GET THE LISTENER ID TO TRACK LISTENER NUMBERS
        if (hasData(content_id)) {
            console.log("listen Start")
            const fetch_str = `/api/public/v0/broadcast/listen?id=${content_id}&table=${content_table}&host=${host}&type=${!hasData(player_state?.listener) ? "1" : "2"}&origin=local&listener=${player_state?.listener}`
            //console.log("fetch_str",fetch_str)
            const res = await fetch(fetch_str,{mode:"no-cors"});
            const json = await res.json()
            if (json) {

                let { outcome
                        , broadcast_listener_id } = json

                //SAVE IT TO MEMORY AND SET IT ACTIVE, SO SWR STARTS TRIGGERING REFRESHES
                await set_player_state(prev=> {
                    return {
                        ...prev
                        ,listener:broadcast_listener_id
                        ,listener_stored_id:broadcast_listener_id
                    }
                })

            }
        }
       
        await set_player_state(prev=> {
            return {
                ...prev
                ,isPlaying:true
            }
        })
        
    }

    //initiate first listen stat
    const listenInitiate = async (content_id,content_table) => {

        if (!hasData(player_state?.listener_stored_id) && hasData(content_id)) {

            const fetch_str = `/api/public/v0/broadcast/listen?id=${content_id}&table=${content_table}&host=${host}&type=${!hasData(player_state?.listener) ? "1" : "2"}&origin=local&listener=${player_state?.listener}`
            //console.log("fetch_str",fetch_str)
            const res = await fetch(fetch_str);
            const json = await res.json()
            if (json) {

                let { outcome
                        , broadcast_listener_id } = json

                //SAVE IT TO MEMORY AND SET IT ACTIVE, SO SWR STARTS TRIGGERING REFRESHES
                
                // set_listener_stored_id(broadcast_listener_id);
                // set_listener(broadcast_listener_id);


                await set_player_state(prev=> {
                    return {
                        ...prev
                        ,listener:broadcast_listener_id
                        ,listener_stored_id:broadcast_listener_id
                    }
                })

             
            }
        }
    }

    const onLiveStart =()=> {
        listenInitiate(content_id,content_table);
        console.log("audio started");
    }

    //run one last data capture and clear SWR refreshs after stream end
    const listenEnd = async (content_id,content_table,channel) => {

        console.log("listen End")
        set_isListening(false);

        onUnmount(channel);
        
        await fetch(`/api/public/v0/broadcast/listen?id=${content_id}&host=${host}&type=0&origin=local&listener=${player_state?.listener}`);
        // set_listener(null);

        await set_player_state(prev=> {
            return {
                ...prev
                ,listener: null //reset state
            }
        })
    }

    const onLivePlay = async ()=> {

        console.log("onPlay triggered")

        await listenStart(content_id,content_table,ably?.channels?.get(channel_id));
        console.log("audio play");
        await set_player_state(prev => {

            return {...prev
                    ,isPlaying:true
                    ,isSeeking:false
                    ,isLoading:false
                    ,isMediaLoaded:true
                }


        })
    }

    const onLivePause = async ()=> {
        listenEnd(content_id,content_table,ably?.channels?.get(channel_id));
        //console.log("audio paused");
        
        await set_player_state(prev=> {
            return {
                ...prev
                ,isPlaying:false
                ,isSeeking:false //reset state
            }
        })
    }

    const onLiveEnded = async ()=> {
        
        console.log("audio ended");
        listenEnd(content_id,content_table,ably?.channels?.get(channel_id));

        await set_player_state(prev=> {
            return {
                ...prev
                ,url:''
                ,file:''
                ,isPlaying:false
                ,isSeeking:false
                ,isEnded:true
            }
        })
       
    }

    const onLiveBuffer = async ()=> {
        await set_player_state(prev=> {
            return {
                ...prev
                ,isBuffering:true
            }
        })
    } 
    

    const onLiveBufferEnd = async ()=> {
        await set_player_state(prev=> {
            return {
                ...prev
                ,isBuffering:false
            }
        })
    }
    



    if (!hasData(stream_url_active)) 
        return (<>
            <div className="text-center text-red-500 font-bold w-full ">
            No stream URL! Refresh to fix this. {player_state?.content_table}_{player_state?.content_id}
            </div>
            </>)

    return (<>
            {ReactPlayer.canPlay(stream_url_active) &&   
                <div className="w-full ">
                    <div className="">
                        {/* STATUS */}
                        {player_state?.content_table}: {player_state?.content_id}
                        File: {stream_url_active}
                        Loaded: {player_state?.isMediaLoaded ? "true" : "false"} | 
                        Buffering: {player_state?.isBuffering ? "true" : "false"} |

                        {/* TOGGLE */}
                        <button onClick={()=>{
                            //set_isPlaying(!isPlaying)
                            set_player_state({...player_state,isPlaying:!player_state.isPlaying})
                        }}
                        >
                            {/* {isPlaying ? "Pause" : "Play"} */}
                            
                            {player_state.isPlaying ? "Pause" : "Play"}
                        </button> |  

                        {/* MUTE */}
                        <button onClick={()=> {
                            // set_isMuted(!isMuted)
                            set_player_state({...player_state
                                            ,isMuted:!player_state?.isMuted
                            })
                        }}>
                            {player_state?.isMuted ? "Unmute" : "Mute"}
                        </button> | 

                        {/* VOLUME */}
                        {player_state?.volume} |
                        <Slider
                            axis='y'
                            y={(player_state?.volume_slider)}
                            ymax={100}
                            onChange={({ y }) => {
                                    set_player_state({...player_state
                                            ,volume:(100-y)/100
                                            ,volume_slider:y
                                    })
                                    // set_volume((100-y)/100)
                                    // set_volume_slider((y))
                                    localStorage.setItem('volume_slider',y?.toString())
                                }}
                            styles={{
                                    active: {
                                        backgroundColor: '#000000'
                                    
                                    },
                                    track: {
                                        backgroundColor: player_state?.isMuted
                                                        ? '#555555'
                                                        : '#0000ff',
                                        display: 'flex',
                                        height: 40
                                    },
                                    thumb: {
                                        width: 15,
                                        height: 15,
                                        backgroundColor: player_state?.isMuted
                                                        ? '#c0c0c0'
                                                        : '#ffffff',
                                        border: '1px solid #a0a0a0'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        

                    </div>
                    {player_state?.bol_stream_local?.toString() == "1" && !player_state?.isMediaLoaded && 
                    <div  className={`w-full text-center h-10 flex items-center content-center`}>
                        <div className="flex-1 text-center animate-pulse">
                            <div className="font-bold">
                                Loading stream...
                            </div>
                            <div className="italic text-xs">
                                Refresh if this takes longer than 10 seconds.
                            </div>
                          hls:  {player_state?.useHLS ? "true" : "false"}
                        </div>
                    </div>
                    }

                    <div  className={`w-full  h-10 ${(player_state?.bol_stream_local?.toString() == "1" && player_state?.isMediaLoaded) || player_state?.bol_stream_local?.toString() == "0" ? " block " : "  block "}`}>
                        <ReactPlayer
                            ref={player_state?.playerRef}
                            url={stream_url_active}
                            config={{
                                    forceAudio: true,
                                    forceHLS: player_state?.useHLS,
                                    hlsOptions: player_state?.useHLS 
                                                ? {
                                                    maxLoadingDelay: 4,
                                                    minAutoBitrate: 0,
                                                    lowLatencyMode: true,
                                                    enableWorker: true,
                                                } 
                                                : {}
                                    //hlsVersion: 0.13.1
                            
                            }}
                            width='100%'
                            height='40px'
                            autoPlay={player_state?.isMediaLoaded}
                            preload={"auto"}
                            controls={false}
                            //playing={isPlaying}
                            playing={player_state?.isPlaying}
                            muted={player_state?.isMuted}
                            volume={player_state?.volume}
                            // onReady={testReady}
                            // onReady={player_state?.player_type == "live" 
                            //             ? onReady 
                            //             : ()=>{}
                            //         }
                            //onProgress={onProgress} //disabled this due to constant rerenders for no reason in the live environment
                            onStart={onLiveStart}
                            
                            onPlay={onLivePlay}
                            //onPause={onLivePause}
                            onEnded={onLiveEnded}
                            // onSeek={()=> {
                            //     set_player_state({...player_state,isSeeking:true})
                            // }}
                            // onError={()=> alert("error")}
                            onBuffer={onLiveBuffer}
                            onBufferEnd={onLiveBufferEnd}
                        />
                    </div>
                </div>
                
            }
        </>)
   
}

export const AudioLive = memo(AudioLiveNested)