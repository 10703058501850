import '../styles/index.css'
import Auth from '@/components/auth/auth'
import { Provider } from 'next-auth/client'
import Script from 'next/script';
import { PlayerWrapper } from '@/components/player/player-wrapper';
import ErrorBoundary from '@/components/templateux/error-boundary'
import { PushProvider } from '@/components/context/push-wrapper'
import { Analytics } from '@vercel/analytics/react';
import ConfirmContextProvider from '@/components/context/confirm-context'
import { ModalWrapper } from '@/components/context/modal-wrapper';

function App({ Component, pageProps }) {

  return (
    <PushProvider>
      <Provider session={pageProps.session}>
        <Auth>
          <ConfirmContextProvider>
            
              <ModalWrapper 
               {...pageProps}
              >
                  <PlayerWrapper
                    {...pageProps}
                  >
                    <ErrorBoundary>
                      <Component 
                        {...pageProps} 
                      />
                      <Analytics />
                    </ErrorBoundary>
                  </PlayerWrapper>
                  
              </ModalWrapper>
              <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICSID}`}
                strategy="afterInteractive"
              />
              <Script id="google-analytics" strategy="afterInteractive">
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){window.dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICSID}');
                `}
              </Script>
          </ConfirmContextProvider>
        </Auth>
      </Provider>
    </PushProvider>
  )
}

export default App
